import React, { useState, useEffect } from 'react';
import upArrowIcon from '../../assets/images/up.png'; // Asegúrate de que la ruta sea correcta

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const styles = {
    scrollToTop: {
      position: 'fixed',
      bottom: '60px',
      right: '10px',
      zIndex: 100,
      cursor: 'pointer',
    },
    scrollToTopIcon: {
      width: '90px',
      height: '90px',
      borderRadius: '100%',
      padding: '20px',
      transition: 'opacity 0.3s, visibility 0.3s',
      display: 'block' // Asegura que la imagen se muestre como bloque para aplicar los estilos
    },
    scrollToTopIconHover: {
      backgroundColor: 'rgba(0,0,0,0.1)' // Color de fondo para hover (ejemplo)
    },
  };

  return (
    <div style={styles.scrollToTop}>
      {isVisible && (
        <div onClick={scrollToTop} style={{ cursor: 'pointer' }}>
          <img
            src={upArrowIcon}
            alt="Go to top"
            style={styles.scrollToTopIcon}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = styles.scrollToTopIconHover.backgroundColor)}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '')}
          />
        </div>
      )}
    </div>
  );
};

export default ScrollToTopButton;
