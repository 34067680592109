import React from 'react';
import Header from '../organisms/Header';
import MainSection from '../organisms/MainSection';
import ImpactSection from '../organisms/ImpactSection';
import AboutSection from '../organisms/AboutSection';
import AchievementsSection from '../organisms/AchievementsSection';
import ServicesSection from '../organisms/ServicesSection';
import TestimonialsSection from '../organisms/TestimonialsSection';
import OtrosServicios from '../organisms/OtrosServicios';
import ContactFormContact from '../organisms/ContactFormContact';
import PasarelaHome from '../organisms/pasarelahome/PasarelaHome';
import Footer from '../organisms/Footer'; // Importa el footer
import './HomePageTemplate.css';
import ScrollToTopButton from '../molecules/ScrollToTopButton'; // Importa tu nuevo botón aquí

const HomePageTemplate = () => {
  return (
    <div className="home-page">
      <Header />
      <MainSection />
      <ImpactSection />
      <AboutSection />
      <AchievementsSection />
      <ServicesSection />
      <OtrosServicios/>
      <PasarelaHome/>
      <TestimonialsSection />
      <ContactFormContact/>
      <Footer />
      <ScrollToTopButton />
    </div>
  );
};

export default HomePageTemplate;
