import React from 'react';
import NosotrasTemplate from '../templates/NosotrasTemplate';

const NosotrasPage = () => {
  return (
    <NosotrasTemplate>
    </NosotrasTemplate>
  );
};

export default NosotrasPage;
