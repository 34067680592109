import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importa tus archivos de traducción
import enTranslation from './locales/en.json';
import esTranslation from './locales/es.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation
    },
    es: {
      translation: esTranslation
    }
  },
  lng: 'es', // Idioma por defecto
  fallbackLng: 'es', // Fallback en caso de no encontrar el idioma seleccionado
  interpolation: {
    escapeValue: false // React ya se encarga de la seguridad de las interpolaciones
  }
});

export default i18n;
