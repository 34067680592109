import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function NewlineText(props) {
    const { text, isMobile } = props;
    return text.split('\n').map((str, index) => (
        <p key={index} style={{ textAlign: isMobile ? 'justify' : 'left', fontSize: '20px', color:'#0A4231', fontFamily: 'Satoshi' }}>{str}</p>
    ));
}

const AboutSectionEndemica = ({
    imageSrc,
    imagePosition = 'left',
    titleColor = '#f06529',
    titleFontSize = '52px',
    marginBottom = '40px',
    fontFamily = 'Anton, sans-serif',
}) => {
    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const styles = {
        aboutSection: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            margin: '0 auto',
            padding: '30px',
            flexDirection: imagePosition === 'right' ? 'row-reverse' : 'row',
            flexWrap: 'wrap',
        },
        aboutImageContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
        },
        aboutImage: {
            maxWidth: '200px',
            height: 'auto',
            display: 'block',
        },
        aboutContent: {
            flex: 2
        },
        aboutTitle: {
            color: titleColor,
            fontSize: isMobile ? '45px' : '45px', // Ajusta el tamaño de la fuente según el tamaño de la pantalla
            marginBottom: marginBottom,
            fontFamily: fontFamily,
            width: '100%',
            textAlign: isMobile ? 'center' : 'left', // Centrado solo en dispositivos móviles
        },
        aboutDescription: {
            lineHeight: '1.6',
            textAlign: 'left',
        },
    };

    return (
        <div style={styles.aboutSection} className='container'>
            {imagePosition === 'left' && (
                <div style={styles.aboutImageContainer}>
                    <img src={imageSrc} alt="About Us" style={styles.aboutImage} />
                </div>
            )}
            <div style={styles.aboutContent}>
                <h2 style={styles.aboutTitle}>
                    {t('aboutSectionEndemica.title')}
                </h2>
                <NewlineText text={t('aboutSectionEndemica.content')} />
            </div>
            {imagePosition === 'right' && (
                <div style={styles.aboutImageContainer}>
                    <img src={imageSrc} alt="About Us" style={styles.aboutImage} />
                </div>
            )}
        </div>
    );
};

export default AboutSectionEndemica;
