import React from 'react';
import { useTranslation } from 'react-i18next';
import useContactForm from '../../hooks/contactformcontact/useContactForm'; // Importar el hook

const ContactFormContact = () => {
  const { t } = useTranslation();
  const { formData, isLoading, handleChange, handleSubmit } = useContactForm(); // Usar el hook

  return (
    <section style={styles.section}>
      <h1 style={styles.header}>{t('contactFormContact.header')}</h1>
      <form style={styles.form} onSubmit={handleSubmit}>
        <div style={styles.row}>
          <input
            type="text"
            name="name"
            placeholder={t('contactFormContact.namePlaceholder')}
            value={formData.name}
            onChange={handleChange}
            style={styles.input}
            disabled={isLoading} // Desactivar el campo mientras se envía
          />
          <input
            type="email"
            name="email"
            placeholder={t('contactFormContact.emailPlaceholder')}
            value={formData.email}
            onChange={handleChange}
            style={styles.input}
            disabled={isLoading}
          />
        </div>
        <div style={styles.row}>
          <input
            type="text"
            name="whatsapp"
            placeholder={t('contactFormContact.whatsappPlaceholder')}
            value={formData.whatsapp}
            onChange={handleChange}
            style={styles.input}
            disabled={isLoading}
          />
          <input
            type="text"
            name="organization"
            placeholder={t('contactFormContact.organizationPlaceholder')}
            value={formData.organization}
            onChange={handleChange}
            style={styles.input}
            disabled={isLoading}
          />
        </div>
        <textarea
          name="message"
          placeholder={t('contactFormContact.supportPlaceholder')}
          value={formData.message}
          onChange={handleChange}
          style={styles.textarea}
          disabled={isLoading}
        ></textarea>
        <button type="submit" style={styles.button} disabled={isLoading}>
          {isLoading ? t('contactFormContact.sending') : t('contactFormContact.submitButton')}
        </button>
      </form>
    </section>
  );
};

const styles = {
  section: {
    padding: '40px',
    backgroundColor: '#ffffff',
    textAlign: 'center',
  },
  header: {
    fontSize: '45px',
    color: '#00B07A',
    fontFamily: 'Anton',
    marginBottom: '30px',
    marginTop: '110px',
  },
  form: {
    maxWidth: '600px',
    margin: '0 auto',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  input: {
    width: '48%',
    padding: '10px',
    borderRadius: '30px',
    border: '1px solid #ccc',
    fontSize: '15px',
    boxSizing: 'border-box',
  },
  textarea: {
    width: '100%',
    padding: '10px',
    borderRadius: '10px',
    border: '1px solid #ccc',
    fontSize: '16px',
    height: '120px',
    marginBottom: '20px',
    boxSizing: 'border-box',
    resize: 'none',
  },
  button: {
    backgroundColor: '#FF5722',
    color: '#ffffff',
    padding: '10px 20px',
    borderRadius: '30px',
    fontSize: '16px',
    fontFamily: 'Satoshi',
    fontWeight: 'bold',
    height: '47px',
    width: '176px',
    border: 'none',
    cursor: 'pointer',
    textTransform: 'uppercase',
    margin: '0 auto',
  },
};

export default ContactFormContact;
