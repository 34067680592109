import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { images } from './images'; // Importa las imágenes desde el archivo separado

const PasarelaHome = () => {
  const { t } = useTranslation();

  const carouselRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (carouselRef.current) {
        carouselRef.current.scrollLeft += 1;
        if (carouselRef.current.scrollLeft >= carouselRef.current.scrollWidth - carouselRef.current.clientWidth) {
          carouselRef.current.scrollLeft = 0;
        }
      }
    }, 20);

    return () => clearInterval(interval);
  }, []);

  return (
    <section style={styles.pasarelaSection}>
      <h3 style={styles.thankYouText}>{t('pasarelaHome.thankYouText')}</h3>
      <h2 style={styles.title}>{t('pasarelaHome.title')}</h2>
      <div style={styles.carouselContainer} ref={carouselRef}>
        {images.map((src, index) => (
          <img key={index} src={src} alt={`Colaborador ${index}`} style={styles.carouselImage} />
        ))}
      </div>
    </section>
  );
};

const styles = {
  pasarelaSection: {
    textAlign: 'center',
    padding: '50px 20px',
    backgroundColor: '#ffffff',
  },
  thankYouText: {
    fontSize: '15px',
    color: '#004d40',
    marginBottom: '10px',
    marginTop: '-100px',
    fontFamily: "'Satoshi', sans-serif",
    fontWeight: 'bold',
  },
  title: {
    fontSize: '45px',
    color: '#00A97F',
    marginBottom: '40px',
    fontFamily: "'Anton', sans-serif",
  },
  carouselContainer: {
    display: 'flex',
    overflowX: 'hidden',
    scrollBehavior: 'smooth',
    whiteSpace: 'nowrap',
    padding: '10px 0',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '-webkit-overflow-scrolling': 'touch',
    position: 'relative',
  },
  carouselImage: {
    height: '150px',
    margin: '0 10px',
    objectFit: 'contain',
  },
  '@media (max-width: 768px)': {
    title: {
      fontSize: '28px',
    },
    carouselImage: {
      height: '120px',
    },
  },
};

export default PasarelaHome;
