import React from 'react';
import { useTranslation } from 'react-i18next';

import IconCompromiso from '../../assets/images/34_Nosotros_Assets/Nuestros_Valores_Compromiso.png';
import IconCongruencia from '../../assets/images/34_Nosotros_Assets/Nuestros_valores_Congruencia.png';
import IconResponsabilidad from '../../assets/images/34_Nosotros_Assets/Nuestros_valores_Responsabilidad.png';
import IconDeterminacion from '../../assets/images/34_Nosotros_Assets/Nuestros_valores_Determinacion.png';
import 'bootstrap/dist/css/bootstrap.min.css';

const Valores = () => {
  const { t } = useTranslation();
  const isMobile = window.innerWidth <= 768;

  const styles = {
    container: {
      maxWidth: '100%',
      marginTop: isMobile ? '-90px' : '0px',
      marginBottom: '90px',
      padding: isMobile ? '0px' : '80px',
    },
    header: {
      color: '#00A884',
      fontSize: isMobile ? '45px' : '45px',
      fontFamily: "'Anton', sans-serif",
      marginBottom: '50px',
      textAlign: 'center',
    },
    valoresGrid: {
      display: 'grid',
      gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
      gap: '20px',
      justifyItems: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    valoresCard: {
      padding: '20px',
      borderRadius: '8px',
      backgroundColor: '#fff',
      width: isMobile ? '90%' : '100%',
      boxSizing: 'border-box',
    },
    valoresIcon: {
      width: '60px',
      height: 'auto',
      marginBottom: '10px',
    },
    valoresTitle: {
      fontSize: isMobile ? '18px' : '20px',
      fontWeight: 'bold',
      color: '#004d40',
      marginBottom: '10px',
    },
    valoresDescription: {
      fontSize: isMobile ? '16px' : '20px',
      color: '#0A4231',
      fontFamily: 'Satoshi',
      textAlign: 'justify', // Justifica el texto
    },
  };

  return (
    <div className="container" style={styles.container}>
      <div className="row">
        <h1 style={styles.header}>
          {t('nosotrasTemplate.valores.title')}
        </h1>
        <div style={styles.valoresGrid}>
          <div style={styles.valoresCard}>
            <img src={IconDeterminacion} alt={t('valores.determinacion.title')} style={styles.valoresIcon} />
            <h3 style={styles.valoresTitle}>{t('valores.determinacion.title')}</h3>
            <p style={styles.valoresDescription}>
              {t('valores.determinacion.description')}
            </p>
          </div>
          <div style={styles.valoresCard}>
            <img src={IconResponsabilidad} alt={t('valores.responsabilidad.title')} style={styles.valoresIcon} />
            <h3 style={styles.valoresTitle}>{t('valores.responsabilidad.title')}</h3>
            <p style={styles.valoresDescription}>
              {t('valores.responsabilidad.description')}
            </p>
          </div>
          <div style={styles.valoresCard}>
            <img src={IconCongruencia} alt={t('valores.congruencia.title')} style={styles.valoresIcon} />
            <h3 style={styles.valoresTitle}>{t('valores.congruencia.title')}</h3>
            <p style={styles.valoresDescription}>
              {t('valores.congruencia.description')}
            </p>
          </div>
          <div style={styles.valoresCard}>
            <img src={IconCompromiso} alt={t('valores.compromiso.title')} style={styles.valoresIcon} />
            <h3 style={styles.valoresTitle}>{t('valores.compromiso.title')}</h3>
            <p style={styles.valoresDescription}>
              {t('valores.compromiso.description')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Valores;
