import React from 'react';
import { useTranslation } from 'react-i18next';

const AvisoPrivacidadTemplate = () => {
  const { t } = useTranslation();

  return (
    <div style={styles.wrapper}>
      <section style={styles.container}>
        <h1 style={styles.title}>{t('avisoPrivacidad.title')}</h1>
        <p style={styles.paragraph}>{t('avisoPrivacidad.paragraph1')}</p>
        <p style={styles.paragraph}>{t('avisoPrivacidad.paragraph2')}</p>
        <p style={styles.paragraph}>{t('avisoPrivacidad.paragraph3')}</p>
        <p style={styles.paragraph}>{t('avisoPrivacidad.paragraph4')}</p>
        <p style={styles.paragraph}>{t('avisoPrivacidad.paragraph5')}</p>
        <p style={styles.paragraph}>{t('avisoPrivacidad.paragraph6')}</p>
        <p style={styles.paragraph}>{t('avisoPrivacidad.paragraph7')}</p>
        <p style={styles.paragraph}>{t('avisoPrivacidad.paragraph8')}</p>
        <p style={styles.paragraph}>{t('avisoPrivacidad.paragraph9')}</p>
        <p style={styles.paragraph}>{t('avisoPrivacidad.paragraph10')}</p>
        <p style={styles.paragraph}>{t('avisoPrivacidad.paragraph11')}</p>
        <p style={styles.paragraph}>{t('avisoPrivacidad.paragraph12')}</p>
        <p style={styles.paragraph}>{t('avisoPrivacidad.paragraph13')}</p>
        <p style={styles.paragraph}>{t('avisoPrivacidad.paragraph14')}</p>
        <p style={styles.paragraph}>{t('avisoPrivacidad.paragraph15')}</p>
        <p style={styles.paragraph}>{t('avisoPrivacidad.paragraph16')}</p>
        <p style={styles.paragraph}>{t('avisoPrivacidad.paragraph17')}</p>
        <p style={styles.paragraph}>{t('avisoPrivacidad.paragraph18')}</p>
        <p style={styles.paragraph}>{t('avisoPrivacidad.paragraph19')}</p>
        <p style={styles.paragraph}>{t('avisoPrivacidad.paragraph20')}</p>
        <p style={styles.paragraph}>{t('avisoPrivacidad.paragraph21')}</p>
      </section>
    </div>
  );
};

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    padding: '40px',
    backgroundColor: '#ffffff',
    maxWidth: '70%',
    marginTop: '140px',
    textAlign: 'justify',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
  },
  title: {
    fontSize: '45px',
    fontWeight: 'bold',
    color: '#00B07A',
    marginBottom: '20px',
    fontFamily: 'Anton',
    textAlign: 'left',
  },
  paragraph: {
    fontSize: '16px',
    color: '#0A4231',
    marginBottom: '20px',
    fontFamily: 'Satoshi'
  },
};

export default AvisoPrivacidadTemplate;
