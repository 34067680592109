// images.js
import frame1 from '../../../assets/images/34_Logos_carrusel/Frame_1.png';
import frame2 from '../../../assets/images/34_Logos_carrusel/Frame_2.png';
import frame3 from '../../../assets/images/34_Logos_carrusel/Frame_3.png';
import frame4 from '../../../assets/images/34_Logos_carrusel/Frame_4.png';
import frame5 from '../../../assets/images/34_Logos_carrusel/Frame_5.png';
import frame7 from '../../../assets/images/34_Logos_carrusel/Frame_7.png';
import frame8 from '../../../assets/images/34_Logos_carrusel/Frame_8.png';
import frame9 from '../../../assets/images/34_Logos_carrusel/Frame_9.png';
import frame10 from '../../../assets/images/34_Logos_carrusel/Frame_10.png';
import frame11 from '../../../assets/images/34_Logos_carrusel/Frame_11.png';
import frame12 from '../../../assets/images/34_Logos_carrusel/Frame_12.png';
import frame13 from '../../../assets/images/34_Logos_carrusel/Frame_13.png';
import frame14 from '../../../assets/images/34_Logos_carrusel/Frame_14.png';
import frame15 from '../../../assets/images/34_Logos_carrusel/Frame_15.png';
import frame16 from '../../../assets/images/34_Logos_carrusel/Frame_16.png';
import frame17 from '../../../assets/images/34_Logos_carrusel/Frame_17.png';
import frame18 from '../../../assets/images/34_Logos_carrusel/Frame_18.png';
import frame19 from '../../../assets/images/34_Logos_carrusel/Frame_19.png';
import frame20 from '../../../assets/images/34_Logos_carrusel/Frame_20.png';
import frame21 from '../../../assets/images/34_Logos_carrusel/Frame_21.png';
import frame22 from '../../../assets/images/34_Logos_carrusel/Frame_22.png';
import frame23 from '../../../assets/images/34_Logos_carrusel/Frame_23.png';
import frame24 from '../../../assets/images/34_Logos_carrusel/Frame_24.png';
import frame25 from '../../../assets/images/34_Logos_carrusel/Frame_25.png';
import frame26 from '../../../assets/images/34_Logos_carrusel/Frame_26.png';
import frame27 from '../../../assets/images/34_Logos_carrusel/Frame_27.png';
import frame28 from '../../../assets/images/34_Logos_carrusel/Frame_28.png';
import frame29 from '../../../assets/images/34_Logos_carrusel/Frame_29.png';
import frame30 from '../../../assets/images/34_Logos_carrusel/Frame_30.png';
import frame31 from '../../../assets/images/34_Logos_carrusel/Frame_31.png';
import frame32 from '../../../assets/images/34_Logos_carrusel/Frame_32.png';
import frame33 from '../../../assets/images/34_Logos_carrusel/Frame_33.png';
import frame34 from '../../../assets/images/34_Logos_carrusel/Frame_34.png';
import frame35 from '../../../assets/images/34_Logos_carrusel/Frame_35.png';
import frame36 from '../../../assets/images/34_Logos_carrusel/Frame_36.png';
import frame37 from '../../../assets/images/34_Logos_carrusel/Frame_37.png';
import frame38 from '../../../assets/images/34_Logos_carrusel/Frame_38.png';
import frame39 from '../../../assets/images/34_Logos_carrusel/Frame_39.png';
import frame40 from '../../../assets/images/34_Logos_carrusel/Frame_40.png';
import frame43 from '../../../assets/images/34_Logos_carrusel/Frame_43.png';
import frame44 from '../../../assets/images/34_Logos_carrusel/Frame_44.png';
import frame45 from '../../../assets/images/34_Logos_carrusel/Frame_45.png';
import frame46 from '../../../assets/images/34_Logos_carrusel/Frame_46.png';
import frame49 from '../../../assets/images/34_Logos_carrusel/Frame_49.png';
import frame50 from '../../../assets/images/34_Logos_carrusel/Frame_50.png';
import frame51 from '../../../assets/images/34_Logos_carrusel/Frame_51.png';
import frame52 from '../../../assets/images/34_Logos_carrusel/Frame_52.png';
import frame55 from '../../../assets/images/34_Logos_carrusel/Frame_55.png';
import frame56 from '../../../assets/images/34_Logos_carrusel/Frame_56.png';
import frame57 from '../../../assets/images/34_Logos_carrusel/Frame_57.png';
import frame61 from '../../../assets/images/34_Logos_carrusel/Frame_61.png';
import frame62 from '../../../assets/images/34_Logos_carrusel/Frame_62.png';
import frame63 from '../../../assets/images/34_Logos_carrusel/Frame_63.png';
import frame67 from '../../../assets/images/34_Logos_carrusel/Frame_67.png';
import frame68 from '../../../assets/images/34_Logos_carrusel/Frame_68.png';
import frame69 from '../../../assets/images/34_Logos_carrusel/Frame_69.png';
import frame70 from '../../../assets/images/34_Logos_carrusel/Frame_70.png';

export const images = [
  frame1, frame2, frame3, frame4, frame5, frame7, frame8, frame9, frame10,
  frame11, frame12, frame13, frame14, frame15, frame16, frame17, frame18, frame19, frame20,
  frame21, frame22, frame23, frame24, frame25, frame26, frame27, frame28, frame29, frame30,
  frame31, frame32, frame33, frame34, frame35, frame36, frame37, frame38, frame39, frame40,
  frame43, frame44, frame45, frame46, frame49, frame50, frame51, frame52, frame55, frame56, frame57,
  frame61, frame62, frame63, frame67, frame68, frame69, frame70
];
