import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SocialIconsGroup from '../molecules/SocialIconsGroup';

const FooterLinksGroup = () => {
  const { t } = useTranslation();
  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (link) => {
    setActiveLink(link);
    window.scrollTo(0, 0);
  };

  return (
    <div style={styles.footerContainer}>
      <div style={styles.leftSection}>
        <span style={styles.copyright}>
          {t('footer.copyright', { year: 2024 })}
        </span>
      </div>
      <div style={styles.centerSection}>
        <Link
          to="/privacy-policy"
          style={{
            ...styles.footerLink,
            ...(activeLink === 'privacy' ? styles.activeFooterLink : {}),
          }}
          onClick={() => handleLinkClick('privacy')}
        >
          {t('footer.privacyPolicy')}
        </Link>
        <Link
          to="/terms-conds"
          style={{
            ...styles.footerLink,
            ...(activeLink === 'terms' ? styles.activeFooterLink : {}),
          }}
          onClick={() => handleLinkClick('terms')}
        >
          {t('footer.termsConditions')}
        </Link>
        <Link
          to="/contacto"
          style={{
            ...styles.footerLink,
            ...(activeLink === 'contacto' ? styles.activeFooterLink : {}),
          }}
          onClick={() => handleLinkClick('contacto')}
        >
          {t('footer.contact')}
        </Link>
      </div>
      <div style={styles.rightSection}>
        <SocialIconsGroup />
      </div>
    </div>
  );
};

const styles = {
  footerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '20px',
    width: '100%',
    boxSizing: 'border-box',
    flexDirection: window.innerWidth <= 768 ? 'column' : 'row', // Cambiar a columna en móviles
  },
  leftSection: {
    flex: 1,
    textAlign: 'left',
    marginBottom: window.innerWidth <= 768 ? '10px' : '0',
  },
  centerSection: {
    flex: 2,
    display: 'flex',
    justifyContent: 'center',
    gap: '15px',
    marginBottom: window.innerWidth <= 768 ? '10px' : '0',
  },
  rightSection: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  copyright: {
    fontSize: '14px',
    color: '#ffffff',
  },
  footerLink: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#ffffff',
    textDecoration: 'none',
    margin: '0 10px',
    transition: 'color 0.3s, text-decoration 0.3s',
    fontFamily: "'Satoshi', sans-serif",
  },
  activeFooterLink: {
    color: '#00A884',
    textDecoration: 'underline',
  },
};

export default FooterLinksGroup;
  