import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ServiceButtons from './ServiceButtons'; // Importa el nuevo componente

// Importa los logos y el CSS
import Logo from '../../assets/images/logo.png';
import LogoBlanco from '../../assets/images/logo_blanco.png';
import './Header.css'; // Asegúrate de que la ruta del CSS sea correcta

// Header Component
const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [logo, setLogo] = useState(Logo);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768); // Nuevo estado para detectar pantallas pequeñas
  const navigate = useNavigate();
  const location = useLocation(); // Obtiene la ruta actual

  const { t } = useTranslation();
  
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogoClick = () => {
    navigate('/');
    window.scrollTo(0, 0); // Desplaza la página hacia arriba al hacer clic en el logo
  };

  // Effect para manejar el logo y el tamaño de pantalla
  useEffect(() => {
    const updateLogoAndScreenSize = () => {
      if (window.innerWidth <= 768) {
        setLogo(LogoBlanco); // Logo blanco para pantallas pequeñas
        setIsSmallScreen(true); // Actualiza el estado si la pantalla es pequeña
      } else {
        setLogo(Logo); // Logo normal para pantallas grandes
        setIsSmallScreen(false); // Actualiza el estado si la pantalla es grande
      }
    };

    // Llama a la función al montar y en cada redimensionamiento
    updateLogoAndScreenSize();
    window.addEventListener('resize', updateLogoAndScreenSize);

    return () => {
      window.removeEventListener('resize', updateLogoAndScreenSize);
    };
  }, []);

  return (
    <header className="header">
      <div className="header-content">
        <div className="logo-container">
          <img 
            src={logo} 
            alt="Endémica" 
            className="logo" 
            onClick={handleLogoClick} 
            style={{ cursor: 'pointer' }} // Añade el estilo de cursor de puntero
          />
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
          <div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
          <div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
        </div>
        <NavigationMenu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
      </div>
      
      {/* Renderiza el componente ServiceButtons solo si estás en la ruta /services y no es pantalla pequeña */}
      {location.pathname === '/services' && !isSmallScreen && <ServiceButtons />}
      
    </header>
  );
};

// NavigationMenu Component
const NavigationMenu = ({ isMenuOpen, toggleMenu }) => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const { t, i18n } = useTranslation();

  const handleLinkClick = (path) => {
    setActiveLink(path);
    toggleMenu(); // Cerrar el menú al hacer clic en un enlace
    window.scrollTo(0, 0); // Desplazar hacia el principio de la página
  };

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <nav className={`navigation-menu ${isMenuOpen ? 'open' : ''}`}>
      <Link
        className={`nav-link ${activeLink === '/' ? 'active' : ''}`}
        to="/"
        onClick={() => handleLinkClick('/')}
      >
        {t('menu.home')}
      </Link>
      <Link
        className={`nav-link ${activeLink === '/services' ? 'active' : ''}`}
        to="/services"
        onClick={() => handleLinkClick('/services')}
      >
        {t('menu.services')}
      </Link>
      <Link
        className={`nav-link ${activeLink === '/nosotras' ? 'active' : ''}`}
        to="/nosotras"
        onClick={() => handleLinkClick('/nosotras')}
      >
        {t('menu.nosotras')}
      </Link>
      <Link
        className={`nav-link ${activeLink === '/contacto' ? 'active' : ''}`}
        to="/contacto"
        onClick={() => handleLinkClick('/contacto')}
      >
        {t('menu.contact')}
      </Link>
      <Link
        className="nav-link"
        onClick={() => handleLanguageChange(i18n.language === 'es' ? 'en' : 'es')}
      >
        {i18n.language === 'es' ? 'English (EN)' : 'Español (ES)'}
      </Link>
    </nav>
  );
};

export default Header;
