// src/components/sections/ConsultingServicesSection.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import NuestrosServiciosConsultoria from '../../organisms/NuestrosServiciosConsultoria';
import { styles } from './ConsultingServicesSection.styles';

const ConsultingServicesSection = ({ isMobile }) => {
    
  const { t } = useTranslation();

  return (
    <div style={styles.container(isMobile)} id="Consultoria">
      <h3 style={styles.headerText}>{t('header.service')}</h3>
      <h1 style={styles.headerTitle}>{t('serviceTitles.consulting')}</h1>
      <div style={styles.overlay}>
        <NuestrosServiciosConsultoria />
      </div>
    </div>
  );
};

export default ConsultingServicesSection;
