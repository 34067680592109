import React from 'react';

const PinkCheckMark = ({ style = {} }) => {
  return (
    <svg 
      width={'30px'} 
      height={'30px'} 
      viewBox="0 0 34 34" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'block' }} // Asegura que el SVG se muestre como un bloque
    >
      <path 
        d="M20 6L9 17L4 12" 
        stroke="#FF0080" 
        strokeWidth="2" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PinkCheckMark;
