// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    background-color: #ffffff;
    overflow-x: hidden;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.heading {
    margin-top: 200px;
    margin-bottom: 20px;
    font-weight: bold;
    color: rgb(0, 176, 122);
    font-size: 35px !important;
    text-align: center;
    font-family: Anton;
    padding: 10px 20px;
    border-radius: 8px;
    background-color: '#fffff';
    transition: transform 0.3s ease;
}

.heading:hover {
    transform: scale(1.05);
}

/* Responsive styles */
@media (max-width: 768px) {
    .heading {
        font-size: 24px;
        font-weight: 100;  
        font-family: "Anton" !important  
    }
}

    @media (min-width: 769px) {
    .heading {
        font-size: 32px;
        margin-top: 0; /* Default for larger screens */
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/templates/services/ServicePageTemplate.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,iBAAiB;IACjB,uBAAuB;IACvB,0BAA0B;IAC1B,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;IAClB,0BAA0B;IAC1B,+BAA+B;AACnC;;AAEA;IACI,sBAAsB;AAC1B;;AAEA,sBAAsB;AACtB;IACI;QACI,eAAe;QACf,gBAAgB;QAChB;IACJ;AACJ;;IAEI;IACA;QACI,eAAe;QACf,aAAa,EAAE,+BAA+B;IAClD;AACJ","sourcesContent":[".container {\r\n    background-color: #ffffff;\r\n    overflow-x: hidden;\r\n}\r\n\r\n.content {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    text-align: left;\r\n    padding: 20px;\r\n    width: 100%;\r\n    box-sizing: border-box;\r\n}\r\n\r\n.heading {\r\n    margin-top: 200px;\r\n    margin-bottom: 20px;\r\n    font-weight: bold;\r\n    color: rgb(0, 176, 122);\r\n    font-size: 35px !important;\r\n    text-align: center;\r\n    font-family: Anton;\r\n    padding: 10px 20px;\r\n    border-radius: 8px;\r\n    background-color: '#fffff';\r\n    transition: transform 0.3s ease;\r\n}\r\n\r\n.heading:hover {\r\n    transform: scale(1.05);\r\n}\r\n\r\n/* Responsive styles */\r\n@media (max-width: 768px) {\r\n    .heading {\r\n        font-size: 24px;\r\n        font-weight: 100;  \r\n        font-family: \"Anton\" !important  \r\n    }\r\n}\r\n\r\n    @media (min-width: 769px) {\r\n    .heading {\r\n        font-size: 32px;\r\n        margin-top: 0; /* Default for larger screens */\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
