// src/components/sections/TrainingSection.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import ServicioCapacitacion from '../../organisms/ServicioCapacitacion';
import { styles } from './TrainingSection.styles';

const TrainingSection = ({ isMobile }) => {
    
  const { t } = useTranslation();

  return (
    <div style={styles.container(isMobile)} id="ServicioCapacitacion">
      <h3 style={styles.headerText(isMobile)}>{t('header.service')}</h3>
      <h2 style={styles.headerTitle}>{t('serviceTitles.training')}</h2>
      <div style={styles.overlay}>
        <ServicioCapacitacion />
      </div>
    </div>
  );
};

export default TrainingSection;
