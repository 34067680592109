// src/components/sections/ImpactAuditSection.styles.js

export const styles = {
    container: (isMobile) => ({
      position: 'relative',
      textAlign: 'center',
      marginBottom: isMobile ? '930px' : '350px',
      marginTop: isMobile ? '0px' : '0px',
      width: '100%',
      height: '400px',
      backgroundColor: '#f8f8f8',
    }),
    headerText: {
      fontSize: '18px',
      color: '#004d40',
      marginBottom: '5px',
      marginTop: '65px',
      fontWeight: 'bold',
    },
    headerTitle: {
      fontSize: '45px',
      color: '#FF007E',
      fontFamily: 'Anton',
    },
    overlay: {
      position: 'absolute',
      top: '500px',
      left: '0',
      right: '0',
      bottom: '0',
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'none',
    },
  };
  