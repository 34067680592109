import React from 'react';
import './ServiceButton.css';

const ServiceButton = ({ text, backgroundColor, marginTop, marginBottom, onClick }) => {
  return (
    <button 
      className="service-button" 
      style={{ 
        backgroundColor: backgroundColor, 
        fontFamily: 'Satoshi', 
        height: '47px', 
        fontWeight: 'bold', 
        position: 'relative', 
        marginTop: marginTop, 
        marginBottom: marginBottom 
      }}
      onClick={onClick} // Aquí se agrega el manejador de clics
    >
      {text}
    </button>
  );
};

export default ServiceButton;
