import React from 'react';
import { useTranslation } from 'react-i18next';

const AvisoPrivacidadTemplate = () => {
  const { t } = useTranslation();

  return (
    <div style={styles.wrapper}>
      <section style={styles.container}>
        <h1 style={styles.title}>{t('terminosCondiciones.title')}</h1>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph1')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph2')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph3')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph4')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph5')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph6')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph7')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph8')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph9')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph10')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph11')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph12')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph13')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph14')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph15')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph16')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph17')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph18')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph19')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph20')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph21')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph22')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph23')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph24')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph25')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph26')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph27')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph28')}
        </p>
        <p style={styles.paragraph}>
          {t('terminosCondiciones.paragraph29')}
        </p>
      </section>
    </div>
  );
};

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    padding: '40px',
    backgroundColor: '#ffffff',
    maxWidth: '70%',
    marginTop: '140px',
    textAlign: 'justify',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
  },
  title: {
    fontSize: '45px',
    color: '#00B07A',
    marginBottom: '20px',
    fontFamily: 'Anton',
    textAlign: 'left',
  },
  subtitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#555555',
    marginTop: '20px',
    marginBottom: '10px',
  },
  paragraph: {
    fontSize: '16px',
    color: '#0A4231',
    marginBottom: '20px',
    fontFamily: 'Satoshi'
  },
};

export default AvisoPrivacidadTemplate;
