import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../organisms/Header';
import TitleComponentNosotras from '../atoms/TitleComponentNosotras';
import AboutSectionEndemica from '../molecules/AboutSectionEndemica';
import AboutSectionCenter from '../molecules/AboutSectionCenter';
import Valores from '../organisms/Valores';
import NuestraEscencia from '../molecules/NuestraEscencia';
import NuestrasExpertas from '../organisms/NuestrasExpertas';
import IconPorqueEndemica from '../../assets/images/34_Nosotros_Assets/Icon_por_que_endemica_.png';
import IconEndemicaAzul from '../../assets/images/34_Inicio_Assets_2/endemica_azul.png';
import IconNuestraEscencia from '../../assets/images/34_Nosotros_Assets/Icon_Nuestra_esencia.png';
import Contactanos from '../organisms/Contactanos';
import Footer from '../organisms/Footer'; 
import ScrollToTopButton from '../molecules/ScrollToTopButton'; 

const NosotrasTemplate = ({ children }) => {
  const { t } = useTranslation();

  const styles = {
    nosotrasTemplate: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      overflowX: 'hidden', // Evita el scroll horizontal
    },
    nosotrasContent: {
      flex: 1,
      padding: '0', // Eliminamos padding extra
    },
    aboutUsSection: {
      maxWidth: '800px',
      margin: '0 auto',
      textAlign: 'center',
    },
    aboutSectionsContainer: {
      padding: '0',   // Eliminamos padding extra
      margin: '0', // Eliminamos margin extra
    },
    aboutSection: {
      marginBottom: '30px',
    },
    nuestraEscenciaSection: {
      padding: '0', // Eliminamos padding extra
    },
    responsive: {
      '@media (max-width: 768px)': {
        nosotrasTemplate: {
          padding: '0',
        },
        valoresTitle: {
          fontSize: '32px',
          textAlign: 'center',
          marginTop: '20px',
        },
        aboutUsTitle: {
          fontSize: '36px',
          textAlign: 'center',
        },
        aboutUsParagraph: {
          fontSize: '14px',
        },
      }
    }
  };

  return (
    <div style={{ ...styles.nosotrasTemplate, paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }} className='container-fluid'>
      <Header />
      <TitleComponentNosotras 
        text={t('nosotrasTemplate.titleComponentNosotrasText')} 
        color="#00A884" 
        align="center" 
      />
      <AboutSectionEndemica
        imageSrc={IconPorqueEndemica}
        imagePosition="left"
        titleColor={t('nosotrasTemplate.aboutSectionEndemica.titleColor')}
        descriptionColor={t('nosotrasTemplate.aboutSectionEndemica.descriptionColor')}
        descriptionAlign={t('nosotrasTemplate.aboutSectionEndemica.descriptionAlign')}
      />
      <AboutSectionCenter
        imageSrc={IconEndemicaAzul}
        titleColor={t('nosotrasTemplate.aboutSectionCenter.titleColor')}
        descriptionColor={t('nosotrasTemplate.aboutSectionCenter.descriptionColor')}
        descriptionAlign={t('nosotrasTemplate.aboutSectionCenter.descriptionAlign')}
      />
      <NuestraEscencia
        imageSrc={IconNuestraEscencia}
        imagePosition="left"
        descriptionAlign={t('nosotrasTemplate.nuestraEscencia.descriptionAlign')}
      />
      <Valores/>
      <NuestrasExpertas/>
      <Contactanos/>
      <Footer />
      <ScrollToTopButton/>
    </div>
  );
};

export default NosotrasTemplate;
