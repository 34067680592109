import React from 'react';
import FooterLinksGroup from '../molecules/FooterLinksGroup';
import FotoFooter from '../../assets/images/34_Inicio_Assets/Foto_footer.png';

// Footer Component
const Footer = () => {
  return (
    <footer style={styles.footer}>
      <div style={styles.footerBackground}>
        <img src={FotoFooter} alt="Footer Background" style={styles.footerBgImage} />
      </div>
      <div style={styles.footerContent}>
        <FooterLinksGroup />
      </div>
    </footer>
  );
};

// CSS in JS
const styles = {
  footer: {
    backgroundColor: '#880035',
    position: 'relative',
    zIndex: 1,
  },
  footerContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#ffffff',
    textAlign: 'center',
    zIndex: 2,
    position: 'relative',
  },
  footerBackground: {
    position: 'relative',
    zIndex: 1,
  },
  footerBgImage: {
    width: '100%',
    height: 'auto'
  },
  footerContentLink: {
    color: '#ffffff',
    textDecoration: 'none',
    margin: '5px 10px',
  },
  footerContentLinkHover: {
    textDecoration: 'underline',
  },
  socialIconsGroup: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  footerLinksGroup: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '10px',
    flexWrap: 'wrap',
  },
};

// Insert styles into the document head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = `
  .footer-content a:hover {
    text-decoration: underline;
  }
`;
document.head.appendChild(styleSheet);

export default Footer;
