import { useState } from 'react';
import Swal from 'sweetalert2';
import sendContactFormData from '../../components/api/sendContactFormDataAPI'; // Importar la función de envío

const useContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    whatsapp: '',
    organization: '',
    message: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // Usar la función reutilizada para enviar los datos
      const response = await sendContactFormData(formData);
      console.log('Solicitud completada con éxito:', response);

      // Mostrar alerta de éxito
      Swal.fire({
        title: '¡Envío Exitoso!',
        text: 'Tu mensaje ha sido enviado correctamente.',
        icon: 'success',
        confirmButtonText: 'Ok'
      });

      // Limpiar el formulario
      setFormData({
        name: '',
        email: '',
        whatsapp: '',
        organization: '',
        message: '',
      });

    } catch (error) {
      console.error('Error al enviar los datos:', error);

      // Mostrar alerta de error
      Swal.fire({
        title: 'Error',
        text: 'Hubo un problema al enviar el mensaje. Inténtalo de nuevo más tarde.',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    formData,
    isLoading,
    handleChange,
    handleSubmit,
  };
};

export default useContactForm;
