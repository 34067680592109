import React from 'react';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import { isMobile } from 'react-device-detect'; // Importa isMobile

function NewlineText({ text, isMobile }) {
    return text.split('\n').map((str, index) => (
        <p key={index} style={{ textAlign: isMobile ? 'justify' : 'left', marginLeft:'15px', marginRight:'15px', fontSize: '20px', color:'#0A4231', fontFamily: 'Satoshi' }}>{str}</p>
    ));
}

const AboutSectionCenter = ({
    imageSrc,
    titleColor = '#f06529',
    titleFontSize = '46px',
    marginBottom = '30px',
    marginTop = '40px'
}) => {
    const { t } = useTranslation();

    // Verifica si es móvil
    const isMobileDevice = isMobile;

    const styles = {
        aboutSection: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: isMobileDevice ? '100%' : '70%', // Ajusta el ancho si es móvil
            margin: '0 auto',
            flexDirection: isMobileDevice ? 'column' : 'row', // Cambia la dirección a columna en móvil
            padding: '20px',
            boxSizing: 'border-box',
            flexWrap: 'wrap',
        },
        aboutContent: {
            flex: 2,
            padding: isMobileDevice ? '0 10px' : '0 20px', // Ajusta padding en móvil
            textAlign: isMobileDevice ? 'center' : 'left', // Centra el texto en móvil
        },
        aboutImageContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '20px',
            boxSizing: 'border-box',
        },
        aboutImage: {
            maxWidth: '100%',
            width: 'auto',
            height: 'auto',
            padding: '40px',
        },
        aboutTitle: {
            fontWeight: 'bold',
            color: titleColor,
            fontSize: isMobileDevice ? '45px' : '45px', // Ajusta tamaño de fuente en móvil
            marginBottom: marginBottom,
            fontFamily: 'Anton',
            textAlign: isMobileDevice ? 'center' : 'left'
        },
        aboutDescription: {
            lineHeight: '1.6',
            textAlign: isMobileDevice ? 'center' : 'left', // Centra el texto en móvil
        },
    };

    return (
        <div className='container' style={{ marginTop: isMobileDevice ? '50px' : '150px', width: '100%' }}>
            <div className='row'>
                <div className='col-sm-1'></div>
                <div className='col-sm-5'>
                    <h1 style={styles.aboutTitle}>
                        {t('aboutSectionCenter.title')}
                    </h1>
                    <NewlineText isMobile={isMobile} text={t('aboutSectionCenter.content')} />
                    <p style={{ textAlign: 'left', fontSize: '20px', color:'#0A4231', fontFamily: 'Satoshi', fontWeight: 'bold', marginLeft: '15px', marginRight: '15px' }}>
                        {t('aboutSectionCenter.content-final')}
                    </p>
                </div>
                <div className='col-sm-5'>
                    <img src={imageSrc} alt="About Us" style={styles.aboutImage} />
                </div>
                <div className='col-sm-1'></div>
            </div>
        </div>
    );
};

export default AboutSectionCenter;
