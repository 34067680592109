import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import iconSocialMarketing from '../../assets/images/34_Inicio_Assets/Icon_Consultoria.png'; 
import { useTranslation } from 'react-i18next';

const NuestrosServiciosConsultoria = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleContactClick = () => {
    navigate('/contacto');
    window.scrollTo(0, 0);
  };

  return (
    <section style={styles.section(isMobile)}>
      <div style={styles.content(isMobile)}>
        <div style={styles.leftContent(isMobile)}>
          <h2 style={styles.title}>{t('nuestrosServiciosConsultoria.title')}</h2>
          <span style={styles.description}>
            {t('nuestrosServiciosConsultoria.description')}
          </span><br/>
          <span style={styles.description}>
            {t('nuestrosServiciosConsultoria.description1')}
          </span><br/>
          <span style={styles.description}>
            {t('nuestrosServiciosConsultoria.description2')}
          </span><br/>
          <span style={styles.description}>
            {t('nuestrosServiciosConsultoria.description3')}
          </span><br/>
          <span style={styles.description}>
            {t('nuestrosServiciosConsultoria.description4')}
          </span><br/>
          <span style={styles.description}>
            {t('nuestrosServiciosConsultoria.description5')}
          </span><br/>
          <span style={styles.description}>
            {t('nuestrosServiciosConsultoria.description6')}
          </span><br/>
          <span style={styles.description}>
            {t('nuestrosServiciosConsultoria.description7')}
          </span><br/>
          <span style={styles.description}>
            {t('nuestrosServiciosConsultoria.description8')}
          </span><br/><br/>
          <span style={styles.description}>
            {t('nuestrosServiciosConsultoria.description9')}
          </span><br/><br/>
          <p style={styles.ideal}>
            <span style={{ fontWeight: 'bold' }}>{t('nuestrosServiciosConsultoria.ideal')}</span>
            <span>{t('nuestrosServiciosConsultoria.idealDetail')}</span>
          </p>
          <p style={styles.entregable}>
            <span style={{ fontWeight: 'bold' }}>{t('nuestrosServiciosConsultoria.entregable')}</span>
            <span>{t('nuestrosServiciosConsultoria.entregableDetail')}</span>
          </p>
          {/* Ajuste para centrar el botón en móviles */}
          <div style={styles.buttonContainer(isMobile)}>
            <button style={styles.button} onClick={handleContactClick}>
              {t('nuestrosServiciosConsultoria.button')}
            </button>
          </div>
        </div>
        <div style={styles.rightContent(isMobile)}>
          <div style={styles.testimonialBox(isMobile)}>
            <img src={iconSocialMarketing} alt="Icono Social Marketing" style={styles.icon} />
            <p style={styles.testimonialTitle}>{t('nuestrosServiciosConsultoria.testimonialTitle')}</p>
            <p style={styles.testimonial}>{t('nuestrosServiciosConsultoria.testimonial')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

const styles = {
  section: (isMobile) => ({
    padding: '40px',
    backgroundColor: '#ffffff',
    marginBottom: '80px',
    textAlign: 'left',
    marginTop: isMobile ? '750px' : '200px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  }),
  content: (isMobile) => ({
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row', // Cambiar a columna en móvil
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: isMobile ? '0px' : '50px',
    maxWidth: '1000px',
    margin: '0 auto',
    flexWrap: 'wrap',
  }),
  leftContent: (isMobile) => ({
    flex: 1,
    paddingRight: isMobile ? '0' : '20px', // Sin padding lateral en móvil
    marginBottom: '20px',
    minWidth: '280px',
    boxSizing: 'border-box',
  }),
  title: {
    fontSize: '28px',
    color: '#00B386',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  description: {
    fontSize: '16px',
    color: '#0A4231',
    marginBottom: '20px',
    fontFamily: 'Satoshi',
  },
  ideal: {
    fontSize: '16px',
    color: '#0A4231',
    marginBottom: '10px',
    fontFamily: 'Satoshi',
  },
  entregable: {
    fontSize: '16px',
    color: '#0A4231',
    marginBottom: '20px',
    fontFamily: 'Satoshi',
  },
  button: {
    backgroundColor: '#00B386',
    color: '#ffffff',
    fontFamily: 'Satoshi',
    padding: '10px 20px',
    height: '47px',
    fontWeight: 'bold',
    width: '176px',
    border: 'none',
    borderRadius: '25px',
    fontSize: '16px',
    cursor: 'pointer',
    textTransform: 'uppercase',
    transition: 'background-color 0.3s ease',
  },
  // Estilos para centrar el botón cuando es móvil
  buttonContainer: (isMobile) => ({
    textAlign: isMobile ? 'center' : 'left', // Centra el botón si es móvil
    width: '100%', // Asegura que ocupe el 100% del contenedor
  }),
  rightContent: (isMobile) => ({
    flex: 1,
    paddingLeft: isMobile ? '0' : '20px', // Sin padding lateral en móvil
    marginTop: isMobile ? '20px' : '0',
    minWidth: '280px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
  }),
  testimonialBox: (isMobile) => ({
    backgroundColor: '#00b07a',
    color: '#ffffff',
    padding: '30px',
    borderRadius: '20px',
    textAlign: 'center',
    height: '350px',
    width: isMobile ? '100%' : '100%', // Ajusta el ancho completo en móvil
    maxWidth: isMobile ? '100%' : '300px',
    margin: '0 auto',
    display: 'flex',
    marginBottom: isMobile ? '0px' : '0px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  icon: {
    width: '45px',
    height: '45px',
    marginBottom: '10px',
  },
  testimonialTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '15px',
  },
  testimonial: {
    fontSize: '16px',
    lineHeight: '1.6',
  },
};

export default NuestrosServiciosConsultoria;
