async function sendContactFormData(data) {

    try {
        
        console.log("Imprimiendo variables de entorno ...");
        console.log(process.env);

        console.log('Valor de NEXT_PUBLIC_API_HOST:', process.env.NEXT_PUBLIC_API_HOST);


      // Obtener la URL de la API desde las variables de entorno
      //const host = process.env.NEXT_PUBLIC_API_HOST;
      const host = "https://endemicasocial.com:3001";
      const url = `${host}/api/v1/contact`;
  
      // Log antes de enviar la solicitud
      console.log('Iniciando solicitud POST a la API...');
      console.log(`URL: ${url}`);
      console.log(`Datos enviados: ${JSON.stringify(data)}`);
  
      // Hacer la petición POST con los datos del formulario usando fetch
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Asegúrate de que los datos se envían como JSON
        },
        body: JSON.stringify(data), // Convierte los datos a JSON antes de enviarlos
      });
  
      // Verificar si la respuesta es correcta (código de estado 200-299)
      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.status} - ${response.statusText}`);
      }
  
      // Obtener los datos de la respuesta
      const responseData = await response.json();
  
      // Log para verificar la respuesta del servidor
      console.log('Solicitud completada con éxito.');
      console.log('Respuesta de la API:', responseData);
  
      return responseData; // Devuelve los datos de la respuesta
    } catch (error) {
      // Log en caso de error
      console.error('Error al enviar los datos a la API:', error.message);
      console.error('Detalles del error:', error);
  
      throw error; // Lanzar el error para que se maneje en otro lugar si es necesario
    }
  }
  
  export default sendContactFormData;
  