import React from 'react';
import { useTranslation } from 'react-i18next';
import PinkCheckMark from '../../components/atoms/PinkCheckMark';
import 'bootstrap/dist/css/bootstrap.min.css';

function NewlineText({ text, isMobile }) {
    return text.split('\n').map((str, index) => {
        const formattedText = str.replace(/(Visión:|Misión:|Vision:|Mission:)/g, (match) => `<strong>${match}</strong>`);
        return <p key={index} style={{ textAlign: isMobile ? 'justify' : 'left', fontSize: '20px', color:'#0A4231', fontFamily: 'Satoshi' }} dangerouslySetInnerHTML={{ __html: formattedText }} />;
    });
}

const NuestraEscencia = ({ 
    imageSrc, 
    imagePosition = 'left', 
    titleColor = '#FF0080'
}) => {
    const { t } = useTranslation();
    const isMobile = window.innerWidth <= 768;

    const styles = {
        aboutSection: {
            display: 'flex',
            flexDirection: imagePosition === 'right' ? 'row-reverse' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '20px',
            boxSizing: 'border-box',
        },
        aboutImage: {
            maxWidth: '100%',
            height: 'auto',
            marginBottom: '50px',
            marginTop: isMobile ? '-90px' : '0px',
        },
        aboutContent: {
            padding: '10px 20px',
        },
        aboutTitle: {
            color: titleColor,
            fontSize: isMobile ? '45px' : '45px', // Ajusta el tamaño de fuente para móvil y escritorio
            fontFamily: 'Anton',
            marginBottom: '20px',
            textAlign: isMobile ? 'center' : 'left', // Centra el título en dispositivos móviles
        },
    };

    return (
        <div className='row'>
            <div className='col-sm-2'></div>
            <div className='col-sm-8'>
                <div style={{ marginTop: '130px', marginBottom: '90px', maxWidth: '100%' }}>
                    <div className="row" style={isMobile ? { ...styles.aboutSection, flexDirection: 'column' } : styles.aboutSection}>
                        <div className={`col-sm-5 ${isMobile ? 'mb-4' : ''}`}>
                            <img 
                                src={imageSrc} 
                                alt="Nuestra Escencia" 
                                style={styles.aboutImage} 
                            />
                        </div>
                        <div className="col-md-7">
                            <h1 style={styles.aboutTitle}>
                                {t('nuestraEsencia.title')}
                            </h1>
                            <div style={styles.aboutContent}>
                                <div className="row">
                                    {!isMobile && (
                                        <div className="col-sm-1">
                                            <PinkCheckMark style={{ width: '24px', height: '24px', marginRight: '10px' }} />
                                        </div>
                                    )}
                                    <div className={isMobile ? 'col-sm-12' : 'col-sm-11'}>
                                        <NewlineText isMobile={isMobile} text={t('nuestraEsencia.vision')} />
                                    </div>
                                </div>
                                <div className="row">
                                    {!isMobile && (
                                        <div className="col-sm-1">
                                            <PinkCheckMark style={{ width: '24px', height: '24px', marginRight: '10px' }} />
                                        </div>
                                    )}
                                    <div className={isMobile ? 'col-sm-12' : 'col-sm-11'}>
                                        <NewlineText text={t('nuestraEsencia.mission')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>          
                </div>
            </div>
            <div className='col-sm-2'></div>
        </div>
    );
};

export default NuestraEscencia;
