import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'; // Importa useTranslation
import leftArrow from '../../assets/images/flecha izq slider.png'; // Flecha izquierda
import rightArrow from '../../assets/images/flecha slider der.png'; // Flecha derecha
import './TestimonialsSection.css';

const testimonials = [
  {
    textKey: "testimonials.remsa.text",
    authorKey: "testimonials.remsa.author"
  },
  {
    textKey: "testimonials.globalTech.text",
    authorKey: "testimonials.globalTech.author"
  },
  {
    textKey: "testimonials.innovateCorp.text",
    authorKey: "testimonials.innovateCorp.author"
  },
  // Puedes agregar más testimonios aquí
];

const TestimonialsSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t } = useTranslation(); // Desestructura el hook useTranslation

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 5000); // Cambia de testimonio cada 5 segundos
    return () => clearInterval(interval);
  }, []);

  // Función para desplazarse a la izquierda
  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
  };

  // Función para desplazarse a la derecha
  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  return (
    <section className="testimonials-section">
      <div className="testimonials-content">
        <h3 className="testimonials-title">{t('testimonials.title')}</h3>
        <h1 className="testimonials-subtitle">{t('testimonials.subtitle')}</h1>
        <div className="testimonials-stars">
          {'★'.repeat(5)}
        </div>
        <p className="testimonials-text">“{t(testimonials[currentIndex].textKey)}”</p>
        <p className="testimonials-author">- {t(testimonials[currentIndex].authorKey)}</p>
      </div>

      {/* Botón para desplazarse hacia la izquierda */}
      <button className="arrow-left" onClick={handlePrevClick}>
        <img src={leftArrow} alt="Left Arrow" />
      </button>

      {/* Botón para desplazarse hacia la derecha */}
      <button className="arrow-right" onClick={handleNextClick}>
        <img src={rightArrow} alt="Right Arrow" />
      </button>
    </section>
  );
};

export default TestimonialsSection;
