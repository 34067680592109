// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-component {
    font-weight: bold;
    margin-top: 40px !important;
    padding: 0;
    text-align: center;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/atoms/TitleComponent.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,2BAA2B;IAC3B,UAAU;IACV,kBAAkB;EACpB","sourcesContent":[".title-component {\r\n    font-weight: bold;\r\n    margin-top: 40px !important;\r\n    padding: 0;\r\n    text-align: center;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
