import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import fabiolaImage from '../../assets/images/34_Nosotros_Assets/Nuestras_Expertas_Fabiola.png';
import kaylaImage from '../../assets/images/34_Nosotros_Assets/Nuestras_Expertas_Kayla.png';
import linkedinIcon from '../../assets/images/linkedin.png'; // Asegúrate de ajustar la ruta según tu estructura de carpetas

const NuestrasExpertas = () => {
  const { t } = useTranslation();

  const [isMobile] = useState(window.innerWidth <= 768);

  const styles = {
    container: {
      maxWidth: '100%',
      margin: '0 auto',
      padding: '40px',
    },
    sectionTitle: {
      textAlign: 'center',
      color: '#004d40',
      fontSize: '23px',
      marginBottom: '60px',
      fontWeight: 'bold',
    },
    sectionSubtitle: {
      textAlign: 'center',
      color: '#00bcd4',
      fontSize: '45px',
      marginTop: '-50px',
      marginBottom: '80px',
      fontFamily: "'Anton', sans-serif"
    },
    expertContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: '40px',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    expertImageContainer: {
      position: 'relative',
      width: '100%',
      maxWidth: '500px',
      height: 'auto',
      marginRight: '20px',
      marginBottom: '20px',
    },
    expertImage: {
      width: '100%',
      height: 'auto',
      borderRadius: '38px',
      objectFit: 'cover',
    },
    linkedinIcon: {
      position: 'absolute',
      bottom: '20px', // Ajusta la posición vertical si es necesario
      left: '20px', // Cambiado para orientar a la izquierda
      width: '30px',
      height: '30px',
      borderRadius: '15%',
      backgroundColor: '#0A66C2', // Azul LinkedIn
      cursor: 'pointer',
    },
    expertInfo: {
      maxWidth: '800px',
      flex: 1,
      width: '100%',
    },
    expertName: (isMobile) => ({
      fontSize: '25px',
      fontWeight: 'bold',
      fontFamily: 'Satoshi',
      color: '#0A4231',
      marginBottom: '15px',
      textAlign: isMobile ? 'center' : 'left'
    }),
    expertDetails: {
      listStyleType: 'disc',
      paddingLeft: '20px',
      marginBottom: '20px',
      color:'#0A4231', 
      fontFamily: 'Satoshi',
      fontSize: '20px',
    },
    expertBio: {
      color: '#0A4231',
      fontFamily: 'Satoshi',
      fontSize: '20px',
      lineHeight: '1.5',
      fontStyle: 'italic', // Cambia el texto a cursiva
      position: 'relative',
      padding: '0 20px',
    },
    quoteMarks: {
      fontSize: '40px',
      color: '#0A4231',
      fontStyle: 'italic',
      position: 'absolute',
    },
    openQuote: {
      top: '-10px',
      left: '0',
    },
    closeQuote: {
      bottom: '-10px',
      right: '0',
    },
    expertContainerKayla: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: '40px',
      flexDirection: 'row-reverse', // Cambiado para mostrar la imagen a la derecha
      flexWrap: 'wrap',
    },
  };

  return (
    <div className='container' style={{ textAlign: isMobile ? 'justify' : 'left', marginTop: isMobile ? '-50px' : '-70px', padding: isMobile ? '30px' : '100px'}}>
      <div className='row'>
        <h2 style={styles.sectionTitle}>{t('nuestrasExpertas.sectionTitle')}</h2>
      </div>
      <div className='row'>
      < h1 style={styles.sectionSubtitle}>{t('nuestrasExpertas.sectionSubtitle')}</h1>
      </div>
      <div className='row'>
        <div className='row'>
          <div className='col-sm-6'>
            <div style={styles.expertImageContainer}>
              <img src={fabiolaImage} alt="Fabiola Figueroa-Navarro" style={styles.expertImage} />
              <a href="https://www.linkedin.com/in/fabiola-figueroa-navarro-77971a10b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target="_blank" rel="noopener noreferrer">
                <img src={linkedinIcon} alt="LinkedIn" style={styles.linkedinIcon} />
              </a>
            </div>
          </div>
          <div className='col-sm-6' style={{marginBottom:'150px'}}>
            <div style={styles.expertInfo}>
              <h4 style={styles.expertName(isMobile)}>{t('nuestrasExpertas.fabiola.name')}</h4>
              <ul style={styles.expertDetails}>
                {t('nuestrasExpertas.fabiola.details', { returnObjects: true }).map((detail, index) => (
                  <li key={index} style={{fontFamily: 'Satoshi'}}>{detail}</li>
                ))}
              </ul>
              <p style={styles.expertBio}>"{t('nuestrasExpertas.fabiola.bio')}"</p>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
  <div className='row'>
    {isMobile ? (
      <>
        {/* Para móviles, primero mostramos la imagen */}
        <div className='col-sm-6' style={{ marginTop: isMobile ? '-90px' : '0px' }}>
          <div style={styles.expertImageContainer}>
            <div style={styles.expertImageContainer}>
              <img src={kaylaImage} alt="Kayla Defino" style={styles.expertImage} />
              <a href="https://www.linkedin.com/in/kayla-defino-7b5303138/" target="_blank" rel="noopener noreferrer">
                <img src={linkedinIcon} alt="LinkedIn" style={styles.linkedinIcon} />
              </a>
            </div>
          </div>
        </div>
        {/* Luego mostramos el texto en móviles */}
        <div className='col-sm-6'>
          <div style={styles.expertInfo}>
            <h4 style={styles.expertName(isMobile)}>{t('nuestrasExpertas.kayla.name')}</h4>
            <ul style={styles.expertDetails}>
              {t('nuestrasExpertas.kayla.details', { returnObjects: true }).map((detail, index) => (
                <li key={index} style={{fontFamily: 'Satoshi'}}>{detail}</li>
              ))}
            </ul>
          </div>
        </div>
      </>
    ) : (
      <>
        {/* Para pantallas grandes, primero mostramos el texto */}
        <div className='col-sm-6'>
          <div style={styles.expertInfo}>
            <h4 style={styles.expertName(isMobile)}>{t('nuestrasExpertas.kayla.name')}</h4>
            <ul style={styles.expertDetails}>
              {t('nuestrasExpertas.kayla.details', { returnObjects: true }).map((detail, index) => (
                <li key={index} style={{fontFamily: 'Satoshi'}}>{detail}</li>
              ))}
            </ul>
          </div>
        </div>
        {/* Luego mostramos la imagen en pantallas grandes */}
        <div className='col-sm-6'>
          <div style={styles.expertImageContainer}>
            <div style={styles.expertImageContainer}>
              <img src={kaylaImage} alt="Kayla Defino" style={styles.expertImage} />
              <a href="https://www.linkedin.com/in/kayla-defino-7b5303138/" target="_blank" rel="noopener noreferrer">
                <img src={linkedinIcon} alt="LinkedIn" style={styles.linkedinIcon} />
              </a>
            </div>
          </div>
        </div>
      </>
    )}
  </div>
</div>

    </div>
  );
};

export default NuestrasExpertas;
