// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-content {
    margin-top: 90px; /* 10px de la franja rosa + 80px de la altura del header */
}
  `, "",{"version":3,"sources":["webpack://./src/components/templates/HomePageTemplate.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB,EAAE,0DAA0D;AAChF","sourcesContent":[".main-content {\r\n    margin-top: 90px; /* 10px de la franja rosa + 80px de la altura del header */\r\n}\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
