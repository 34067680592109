import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import impactImage from '../../assets/images/34_Inicio_Assets/Mask_group.png';
import orangeNike from '../../assets/images/orange_nikepng.png';
import 'bootstrap/dist/css/bootstrap.min.css';

// Estilos en línea
const styles = {
  section: (isMobile) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: isMobile ? '40px' : '0px',
    alignItems: 'center',
    padding: isMobile ? '0px' : '80px',
    backgroundColor: '#ffffff',
  }),
  image: (isMobile) => ({
    width: isMobile ? '250px' : '350px',
    height: isMobile ? '250px' : '350px',
  }),
  heading: (isMobile) => ({
    color: '#F25C24',
    fontSize: isMobile ? '45px' : '45px',
    textAlign: 'center',
    marginBottom: isMobile ? '50px' : '100px',
    fontFamily: 'Anton'
  }),
  paragraph: (isMobile) => ({
    fontSize: isMobile ? '20px' : '20px',
    fontFamily: 'Satoshi',
    padding: isMobile ? '40px' : '0px',
    marginBottom: '-20px',
    color: '#0A4231',
  }),
  boldParagraph: {
    fontWeight: 'bold',
    padding: '50px',
    marginTop: '-90px'
  },
  list: (isMobile) => ({
    listStyle: 'none',
    padding: '50px',
    color: '#0A4231',
    marginBottom: '20px',
    fontSize: isMobile ? '20px' : '20px',
    fontFamily: 'Satoshi',
  }),
  listItem: (isMobile) => ({
    marginBottom: '10px',
    display: isMobile ? 'block' : 'flex',
    alignItems: 'center',
    color: '#0A4231',
    fontSize: isMobile ? '20px' : '20px',
    fontFamily: 'Satoshi',
  }),
  checkmark: (isMobile) => ({
    width: isMobile ? '20px' : '20px',
    height: isMobile ? '20px' : '20px',
    marginRight: '10px',
  }),
  button: (isMobile) => ({
    backgroundColor: '#F25C24',
    color: '#ffffff',
    width: isMobile ? '120px' : '176px',
    height: '47px',
    fontWeight: 'bold',
    padding: '10px 30px',
    border: 'none',
    borderRadius: '50px',
    fontFamily: 'Satoshi',
    fontSize: isMobile ? '12px' : '15px',
    cursor: 'pointer',
    textTransform: 'uppercase',
    transition: 'background-color 0.3s ease',
    display: 'block', // Asegura que el botón se trate como un elemento de bloque
    margin: isMobile ? '0 auto' : 'initial', // Centra el botón en pantallas móviles
  }),
  buttonHover: {
    backgroundColor: '#F25C24',
  },
};

const ImpactSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/nosotras');
  };

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      if (mobile !== isMobile) {
        setIsMobile(mobile);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);

  return (
    <section style={styles.section(isMobile)} className="container-fluid">
      <div className="row">
        {isMobile ? (
          // Renderización para dispositivos móviles
          <>
            <div className="col-12 d-flex flex-column justify-content-center">
              <h1 style={styles.heading(isMobile)}>{t('impactSection.title')}</h1>
              <div className="col-12 d-flex justify-content-center">
                <img src={impactImage} alt={t('impactSection.title')} style={styles.image(isMobile)} />
              </div>
              <p style={styles.paragraph(isMobile)}>{t('impactSection.intro')}</p>
              <ul style={styles.list(isMobile)}>
                <li style={styles.listItem(isMobile)}>
                  <img src={orangeNike} alt="Checkmark" style={{ width: '23px', height: '23px', marginRight: '10px', fontSize: '20px' }} />
                  {t('impactSection.list.item1')}
                </li>
                <li style={styles.listItem(isMobile)}>
                <img src={orangeNike} alt="Checkmark" style={{ width: '23px', height: '23px', marginRight: '10px', fontSize: '20px' }} />
                  {t('impactSection.list.item2')}
                </li>
                <li style={styles.listItem(isMobile)}>
                <img src={orangeNike} alt="Checkmark" style={{ width: '23px', height: '23px', marginRight: '10px', fontSize: '20px' }} />
                  {t('impactSection.list.item3')}
                </li>
              </ul>
              <p style={{ ...styles.paragraph(isMobile), ...styles.boldParagraph }}>
                {t('impactSection.conclusion')}
              </p>
              <button
                style={styles.button(isMobile)}
                onMouseOver={(e) => (e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor)}
                onMouseOut={(e) => (e.currentTarget.style.backgroundColor = styles.button.backgroundColor)}
                onClick={handleButtonClick}
              >
                {t('impactSection.button')}
              </button>
            </div>
          </>
        ) : (
          // Renderización para dispositivos de escritorio
          <>
            <div className="row">
              <div className="col-12">
                <h1 style={styles.heading(isMobile)}>{t('impactSection.title')}</h1>
              </div>
              <div className="col-12 d-flex">
                <div className="col-sm-6">
                  <div className="d-flex justify-content-center">
                    <img src={impactImage} alt={t('impactSection.title')} style={styles.image(isMobile)} />
                  </div>
                </div>
                <div className="col-sm-6">
                  <p style={styles.paragraph(isMobile)}>{t('impactSection.intro')}</p>
                  <ul style={styles.list(isMobile)}>
                    <li style={styles.listItem(isMobile)}>
                      <img src={orangeNike} alt="Checkmark" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                      {t('impactSection.list.item1')}
                    </li>
                    <li style={styles.listItem(isMobile)}>
                      <img src={orangeNike} alt="Checkmark" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                      {t('impactSection.list.item2')}
                    </li>
                    <li style={styles.listItem(isMobile)}>
                      <img src={orangeNike} alt="Checkmark" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                      {t('impactSection.list.item3')}
                    </li>
                  </ul>
                  <p style={{ ...styles.paragraph(isMobile), ...styles.boldParagraph }}>
                    {t('impactSection.conclusion')}
                  </p>
                  <button
                    style={styles.button(isMobile)}
                    onMouseOver={(e) => (e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor)}
                    onMouseOut={(e) => (e.currentTarget.style.backgroundColor = styles.button.backgroundColor)}
                    onClick={handleButtonClick}
                  >
                    {t('impactSection.button')}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default ImpactSection;
