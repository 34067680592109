import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from './i18n';
import HomePage from './components/pages/HomePage';
import ServicePage from './components/pages/ServicePage'; 
import NosotrasPage from './components/pages/NosotrasPage';
import TerminosCondicionesPage from './components/pages/TerminosCondicionesPage';
import ContactPage from './components/pages/ContactPage'; 
import AvisoPrivacidadPage from './components/pages/AvisoPrivacidadPage'; 

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div>
      <button onClick={() => changeLanguage('en')}>English</button>
      <button onClick={() => changeLanguage('es')}>Español</button>
    </div>
  );
}

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <div className="App">
          <LanguageSwitcher />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/services" element={<ServicePage />} />
            <Route path="/nosotras" element={<NosotrasPage />} />
            <Route path="/contacto" element={<ContactPage />} />
            <Route path="/privacy-policy" element={<AvisoPrivacidadPage />} />
            <Route path="/terms-conds" element={<TerminosCondicionesPage />} />
          </Routes>
        </div>
      </Router>
    </I18nextProvider>
  );
}

export default App;
