import React from 'react';
import { useTranslation } from 'react-i18next'; // Importa el hook useTranslation
import 'bootstrap/dist/css/bootstrap.min.css';

const AboutSection = () => {
  const { t } = useTranslation(); // Desestructura t del hook useTranslation

  return (
    <section style={styles.aboutSection} className="container-fluid">
      <div className="row">
        <div className="row">
        <h2 style={styles.title}>{t('aboutSection.title')}</h2>
        </div>
        <div className="row">
          <h3 style={styles.subtitle}>{t('aboutSection.subtitle')}</h3>
        </div>
        <div className="row">
          <p style={styles.description}>
            {t('aboutSection.description')}<br/><br/>
            {t('aboutSection.description_1')}<br/><br/>
            {t('aboutSection.description_2')}<br/><br/>
            {t('aboutSection.description_3')}
          </p>
        </div>
      </div>
    </section>
  );
};

const styles = {
  aboutSection: {
    textAlign: 'center',
    backgroundColor: '#fff',
  },
  title: {
    color: '#FF0080', // Color rosa
    fontSize: '45px',
    marginBottom: '20px',
    fontFamily: "'Anton', sans-serif"
  },
  subtitle: {
    color: '#004d40', // Color verde oscuro
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '30px',
    fontFamily: "'Satoshi', sans-serif", // Aplicando Satoshi como fuente base
  },
  description: {
    fontSize: '20px',
    color: '#004d40', // Color verde oscuro
    maxWidth: '80%',
    margin: '0 auto',
    lineHeight: '1.6',
    textAlign: 'center',
    fontFamily: "'Satoshi', sans-serif", // Aplicando Satoshi como fuente base
  },
};

export default AboutSection;
