import React, { useState, useEffect } from 'react';
import Header from '../../organisms/Header';
import Footer from '../../organisms/Footer';
import CallToAction from '../../molecules/CallToAction';
import ScrollToTopButton from '../../molecules/ScrollToTopButton';
import MarketingSection from './MarketingSection';
import ImpactAuditSection from './ImpactAuditSection';
import ConsultingServicesSection from './ConsultingServicesSection';
import TrainingSection from './TrainingSection';
import ServiceButton from '../../atoms/ServiceButton';
import { useTranslation } from 'react-i18next';
import './ServicePageTemplate.css'; // Import the CSS file

const ServicePageTemplate = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // State to control the visibility of sections
  const [showMarketingSection, setShowMarketingSection] = useState(false);
  const [showImpactAuditSection, setShowImpactAuditSection] = useState(false);
  const [showConsultingSection, setShowConsultingSection] = useState(false);
  const [showTrainingSection, setShowTrainingSection] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="">
      <Header />
      <div className="content" style={{ marginTop: isMobile ? '-100px' : '300px' }}>
        
        {isMobile && (
          <>
            <h1 className="heading">
              {t('servicesSection.buscas_algo_mas')}<br/>
              {t('servicesSection.buscas_algo_mas_2')}
            </h1>

            {/* Service Buttons */}
            <ServiceButton 
              text={t('servicesList.socialMarketing')} 
              backgroundColor="#F25C24"
              marginTop={'20px'}
              onClick={() => setShowMarketingSection(!showMarketingSection)}
            />
            {showMarketingSection && <MarketingSection isMobile={isMobile} />}
            
            <ServiceButton 
              text={t('servicesList.impactAudit')} 
              backgroundColor="#F80076"
              marginTop={'20px'}
              onClick={() => setShowImpactAuditSection(!showImpactAuditSection)}
            />
            {showImpactAuditSection && <ImpactAuditSection isMobile={isMobile} />}
            
            <ServiceButton 
              text={t('servicesList.consulting')} 
              backgroundColor="#00B07A"
              marginTop={'20px'}
              onClick={() => setShowConsultingSection(!showConsultingSection)}
            />
            {showConsultingSection && <ConsultingServicesSection isMobile={isMobile} />}
            
            <ServiceButton 
              text={t('servicesList.training')} 
              backgroundColor="#fbb017"
              marginTop={'20px'}
              onClick={() => setShowTrainingSection(!showTrainingSection)}
            />
            {showTrainingSection && <TrainingSection isMobile={isMobile} />}
          </>
        )}

        {/* Show all sections if not mobile */}
        {!isMobile && (
          <>
            <MarketingSection isMobile={isMobile} />
            <ImpactAuditSection isMobile={isMobile} />
            <ConsultingServicesSection isMobile={isMobile} />
            <TrainingSection isMobile={isMobile} />
          </>
        )}
        
        <CallToAction 
          text={t('cta.text')}
          buttonText={t('cta.button')}
          buttonColor="#f06529"
          widthButton='180px'
          marginTop={isMobile ? '40px' : '320px'}
        />
      </div>
      <Footer />
      <ScrollToTopButton />
    </div>
  );
};

export default ServicePageTemplate;
