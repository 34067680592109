import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Contactanos = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contacto');
    window.scrollTo(0, 0);
  };

  const styles = {
    container: {
      textAlign: 'center',
      padding: '40px',
      marginTop: '20px',
    },
    heading: {
      color: '#00B07A',
      fontSize: '45px',
      fontFamily: 'Anton',
      marginBottom: '30px',
      '@media (max-width: 768px)': {
        fontSize: '28px',
      },
    },
    button: {
      backgroundColor: '#F06529',
      color: '#FFFFFF',
      border: 'none',
      borderRadius: '50px',
      padding: '10px',
      fontSize: '16px',
      fontFamily: 'Satoshi',
      fontWeight: 'bold',
      cursor: 'pointer',
      width: '176px',
      height: '47px',
      transition: 'background-color 0.3s ease',
      '@media (max-width: 768px)': {
        padding: '12px 40px',
        fontSize: '14px',
      },
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>{t('contactanos.heading')}</h2>
      <button style={styles.button} onClick={handleClick}>
        {t('contactanos.button')}
      </button>
    </div>
  );
};

export default Contactanos;
