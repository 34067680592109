import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import iconSocialMarketing from '../../assets/images/34_Inicio_Assets/Icon_Social_Marketing.png';

const NuestroServicioMkt = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Usar estado para manejar el tamaño de pantalla y ajustar si es móvil o no
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Efecto para ajustar el estado si el usuario cambia el tamaño de la pantalla
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleContactClick = () => {
    navigate('/contacto');
    window.scrollTo(0, 0);
  };

  return (
    <section style={styles.section(isMobile)}>
      <div style={styles.content(isMobile)}>
        <div style={styles.leftContent}>
          <h2 style={styles.title}>{t('nuestroServicioMkt.title')}</h2>
          <p style={styles.description}>
            {t('nuestroServicioMkt.description')}
          </p>
          <p style={styles.ideal}>
            <span style={styles.bold}>{t('nuestroServicioMkt.ideal')}</span>
            <span>{t('nuestroServicioMkt.idealDetail')}</span>
          </p>
          <p style={styles.entregable}>
            <span style={styles.bold}>{t('nuestroServicioMkt.entregable')}</span>
            <span>{t('nuestroServicioMkt.entregableDetail')}</span>
          </p>
          {/* Ajuste del estilo para centrar el botón en móviles */}
          <div style={styles.buttonContainer(isMobile)}>
            <button style={styles.button} onClick={handleContactClick}>
              {t('nuestroServicioMkt.button')}
            </button>
          </div>
        </div>
        <div style={styles.rightContent(isMobile)}>
          <div style={styles.testimonialBox}>
            <img src={iconSocialMarketing} alt="Icono Social Marketing" style={styles.icon} />
            <p style={styles.testimonialTitle}>{t('nuestroServicioMkt.testimonialTitle')}</p>
            <p style={styles.testimonial}>{t('nuestroServicioMkt.testimonial')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

const styles = {
  section: (isMobile) => ({
    padding: isMobile ? '20px' : '40px',
    backgroundColor: '#ffffff',
    marginBottom: isMobile ? '280px' : '80px',
    marginTop: isMobile ? '620px' : '0px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  }),
  content: (isMobile) => ({
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row', // Cambia a columna en móviles
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: isMobile ? '0px' : '10px',
    maxWidth: '1000px',
    margin: '0 auto',
    flexWrap: isMobile ? 'wrap' : 'nowrap', // Forzar el wrap solo en móvil
  }),
  leftContent: {
    flex: 1,
    paddingRight: '20px',
    minWidth: '300px',
    boxSizing: 'border-box',
    textAlign: 'left',
    marginBottom: '20px', // Añade margen para que haya espacio entre bloques en móviles
  },
  rightContent: (isMobile) => ({
    flex: 1,
    paddingLeft: '20px',
    minWidth: '300px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: isMobile ? '20px' : '0', // Añade margen superior en móviles
  }),
  title: {
    fontSize: '28px',
    color: '#F25C24',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  description: {
    fontSize: '16px',
    color: '#0A4231',
    fontFamily: 'Satoshi',
    marginBottom: '20px',
  },
  ideal: {
    fontSize: '16px',
    color: '#0A4231',
    marginBottom: '10px',
    fontFamily: 'Satoshi',
  },
  entregable: {
    fontSize: '16px',
    color: '#0A4231',
    marginBottom: '20px',
    fontFamily: 'Satoshi',
  },
  bold: {
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: '#F25C24',
    color: '#ffffff',
    padding: '10px 20px',
    border: 'none',
    fontWeight: 'bold',
    borderRadius: '25px',
    fontSize: '16px',
    height: '47px',
    width: '176px',
    fontFamily: 'Satoshi',
    cursor: 'pointer',
    textTransform: 'uppercase',
    transition: 'background-color 0.3s ease',
  },
  // Estilos para centrar el botón cuando es móvil
  buttonContainer: (isMobile) => ({
    textAlign: isMobile ? 'center' : 'left', // Centra el botón si es móvil
    width: '100%', // Asegura que ocupe el 100% del contenedor
  }),
  testimonialBox: {
    backgroundColor: '#F36430',
    color: '#ffffff',
    padding: '30px',
    borderRadius: '20px',
    textAlign: 'center',
    height: '350px',
    width: '100%',
    maxWidth: '300px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: '40px',
    height: '40px',
    marginBottom: '10px',
  },
  testimonialTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '15px',
  },
  testimonial: {
    fontSize: '16px',
    lineHeight: '1.6',
  },
};

export default NuestroServicioMkt;
