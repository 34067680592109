// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service-button {
    border: none;
    border-radius: 30px;
    padding: 10px 20px;
    color: white;
    font-size: 15px;
    width: 220px;
    font-weight: normal;
    cursor: pointer;
    margin: 10px;
    transition: transform 0.3s ease;
  }
  
  .service-button:hover {
    transform: scale(1.05);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/atoms/ServiceButton.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,YAAY;IACZ,+BAA+B;EACjC;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":[".service-button {\r\n    border: none;\r\n    border-radius: 30px;\r\n    padding: 10px 20px;\r\n    color: white;\r\n    font-size: 15px;\r\n    width: 220px;\r\n    font-weight: normal;\r\n    cursor: pointer;\r\n    margin: 10px;\r\n    transition: transform 0.3s ease;\r\n  }\r\n  \r\n  .service-button:hover {\r\n    transform: scale(1.05);\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
