import Header from '../organisms/Header';
import Footer from '../organisms/Footer';
import ContactFormContact from '../organisms/ContactFormContact';
import ScrollToTopButton from '../molecules/ScrollToTopButton'; // Importa tu nuevo botón aquí

const ContactPageTemplate = () => {
  return (
    <div className="contact-page">
      <Header />
      <ContactFormContact/>
      <Footer />
      <ScrollToTopButton />
    </div>
  );
};

export default ContactPageTemplate;