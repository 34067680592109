import React from 'react';
import TerminosCondicionesTemplate from '../templates/TerminosCondicionesTemplate';
import Header from '../organisms/Header'; // Asegúrate de que la ruta sea correcta
import Footer from '../organisms/Footer'; // Asegúrate de que la ruta sea correcta
import ScrollToTopButton from '../molecules/ScrollToTopButton'; // Importa tu nuevo botón aquí

const TerminosCondicionesPage = () => {
  return (
    <div>
      <Header />
      <TerminosCondicionesTemplate />
      <Footer />
      <ScrollToTopButton />
    </div>
  );
};

export default TerminosCondicionesPage;
