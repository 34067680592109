import React from 'react';
import ServiceButton from '../atoms/ServiceButton';
import { useTranslation } from 'react-i18next';

const ServiceButtons = () => {
  
  const { t } = useTranslation();

  return (
    <div className="container-fluid">
      <div className="col-sm-12 d-flex justify-content-center"> {/* Clases de Bootstrap para centrar */}
        <a href="#NuestroServicioMKT" className="link m-2"> {/* Añadimos margen para separar los botones */}
          <ServiceButton 
            text={t('servicesList.socialMarketing')} 
            backgroundColor="#F25C24"
          />
        </a>
        <a href="#AuditoriaImpacto" className="link m-2">
          <ServiceButton 
            text={t('servicesList.impactAudit')} 
            backgroundColor="#F80076"
          />
        </a>
        <a href="#Consultoria" className="link m-2">
          <ServiceButton 
            text={t('servicesList.consulting')} 
            backgroundColor="#00B07A"
          />
        </a>
        <a href="#ServicioCapacitacion" className="link m-2">
          <ServiceButton 
            text={t('servicesList.training')} 
            backgroundColor="#fbb017"
          />
        </a>
      </div>
    </div>
  );
};

export default ServiceButtons;
