// src/components/sections/ImpactAuditSection.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import AuditoriaImpacto from '../../organisms/AuditoriaImpacto';
import { styles } from './ImpactAuditSection.styles';

const ImpactAuditSection = ({ isMobile }) => {
    
  const { t } = useTranslation();

  return (
    <div style={styles.container(isMobile)} id="AuditoriaImpacto">
      <h3 style={styles.headerText}>{t('header.service')}</h3>
      <h1 style={styles.headerTitle}>{t('serviceTitles.impactAudit')}</h1>
      <div style={styles.overlay}>
        <AuditoriaImpacto />
      </div>
    </div>
  );
};

export default ImpactAuditSection;
