import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ServicesSection = () => {
  const [isMobile, setIsMobile] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  // Detecta si la pantalla es móvil
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);
    const handleResize = () => setIsMobile(mediaQuery.matches);

    mediaQuery.addListener(handleResize);
    return () => mediaQuery.removeListener(handleResize);
  }, []);

  const handleButtonClick = () => {
    window.scrollTo(0, 0); // Desplaza la página a la parte superior
    navigate('/contacto');
  };

  return (
    <section style={styles.servicesSection} className="container-fluid">
        <div style={styles.content}>
          <div style={styles.textContent}>
            <h3 style={styles.smallTitle(isMobile)}>{t('servicesSection.smallTitle')}</h3>
            <h2 style={styles.title(isMobile)}>{t('servicesSection.title')}</h2>
          </div>
          <div style={styles.buttonContainer}>
            <button
              style={styles.contactButton}
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = styles.contactButtonHover.backgroundColor}
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = styles.contactButton.backgroundColor}
              onClick={handleButtonClick}
            >
              {t('servicesSection.contactButton')}
            </button>
          </div>
        </div>
    </section>
  );
};

const styles = {
  servicesSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '1200px',
    width: '100%',
    padding: '0 20px',
    flexWrap: 'wrap', // Permite que los elementos se envuelvan en pantallas pequeñas
  },
  textContent: {
    flex: 1,
    marginBottom: '20px', // Añadido margen inferior para espaciado en pantallas pequeñas
  },
  smallTitle: (isMobile) => ({
    color: '#004d40',
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
    textAlign: isMobile ? 'center' : 'left'
  }),
  title: (isMobile) => ({
    color: '#00bcd4',
    fontSize: '45px',
    fontFamily: 'Anton',
    textAlign: isMobile ? 'center' : 'left'
  }),
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    marginTop: '20px', // Añadido margen superior para espaciado en pantallas pequeñas
  },
  contactButton: {
    backgroundColor: '#00bcd4',
    color: '#ffffff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '50px',
    fontSize: '16px',
    fontFamily: 'Satoshi',
    width: '190px',
    fontWeight: 'bold',
    height: '42px',
    cursor: 'pointer',
    textTransform: 'uppercase',
    transition: 'background-color 0.3s ease',
  },
  contactButtonHover: {
    backgroundColor: '#0097a7',
  },

  // Media Queries para hacerlo responsivo
  '@media (max-width: 768px)': {
    content: {
      flexDirection: 'column', // Cambia la dirección a columna en pantallas medianas
      textAlign: 'center',
    },
    title: {
      fontSize: '32px', // Reduce el tamaño de la fuente en pantallas medianas
    },
    contactButton: {
      fontSize: '16px', // Reduce el tamaño de la fuente del botón en pantallas medianas
    },
  },

  '@media (max-width: 480px)': {
    title: {
      fontSize: '28px', // Reduce aún más el tamaño de la fuente en pantallas pequeñas
    },
    contactButton: {
      fontSize: '14px', // Reduce aún más el tamaño de la fuente del botón en pantallas pequeñas
      padding: '8px 16px', // Ajusta el padding del botón en pantallas pequeñas
    },
  },
};

// Insert styles into the document head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = `
  ${Object.entries(styles).map(([key, value]) =>
    typeof value === 'object'
      ? `@media ${key} { ${Object.entries(value).map(([prop, val]) => `${prop}: ${val};`).join(' ')} }`
      : `${key} { ${Object.entries(value).map(([prop, val]) => `${prop}: ${val};`).join(' ')} }`
  ).join(' ')}
`;
document.head.appendChild(styleSheet);

export default ServicesSection;
