import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import auditoriaIcon from '../../assets/images/34_Inicio_Assets/Icon_Auditoria_de_Impacto_white.png';
import consultoriaIcon from '../../assets/images/34_Inicio_Assets/Icon_Consultoria_white.png';
import marketingIcon from '../../assets/images/34_Inicio_Assets/Icon_Social_Marketing_white.png';
import capacitacionIcon from '../../assets/images/34_Inicio_Assets/Icon_Capacitacion_white.png';

const OtrosServicios = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleAuditoriaClick = () => {
    navigate('/services#AuditoriaImpacto');
    setTimeout(() => {
      const section = document.getElementById("AuditoriaImpacto");
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 0);
  };

  const handleConsultoriaClick = () => {
    navigate('/services#Consultoria');
    setTimeout(() => {
      const section = document.getElementById("Consultoria");
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 0);
  };

  const handleNuestroServicioMKTClick = () => {
    navigate('/services#NuestroServicioMKT');
    setTimeout(() => {
      const section = document.getElementById("NuestroServicioMKT");
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 0);
  };

  const handleServicioCapacitacionClick = () => {
    navigate('/services#ServicioCapacitacion');
    setTimeout(() => {
      const section = document.getElementById("ServicioCapacitacion");
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 0);
  };

  return (
    <section className='row'>
        <div className='col-sm-3'>
        </div>
        <div className='col-sm-6'>
          <div style={styles.servicesSection}>
            <div style={styles.servicesGrid}>
              
              <div style={styles.serviceCard}>
                <img src={auditoriaIcon} alt={t('otrosServicios.auditoriaTitle')} style={styles.icon} />
                <h3 style={styles.serviceTitle}>{t('otrosServicios.auditoriaTitle')}</h3>
                <p style={styles.serviceDescription}>{t('otrosServicios.auditoriaDescription')}</p>
                <button 
                  style={{ ...styles.button, backgroundColor: '#FF0080' }} 
                  onClick={handleAuditoriaClick}
                >
                  {t('otrosServicios.buttonText')}
                </button>
              </div>
              
              <div style={styles.serviceCard}>
                <img src={consultoriaIcon} alt={t('otrosServicios.consultoriaTitle')} style={styles.icon} />
                <h3 style={styles.serviceTitle}>{t('otrosServicios.consultoriaTitle')}</h3>
                <p style={styles.serviceDescription}>{t('otrosServicios.consultoriaDescription')}</p>
                <button 
                  onClick={handleConsultoriaClick}
                  style={{ ...styles.button, backgroundColor: '#00B07A' }}>{t('otrosServicios.buttonText')}
                </button>
              </div>

              <div style={styles.serviceCard}>
                <img src={marketingIcon} alt={t('otrosServicios.marketingTitle')} style={styles.icon} />
                <h3 style={styles.serviceTitle}>{t('otrosServicios.marketingTitle')}</h3>
                <p style={styles.serviceDescription}>{t('otrosServicios.marketingDescription')}</p>
                <button 
                  onClick={handleNuestroServicioMKTClick}
                  style={{ ...styles.button, backgroundColor: '#FF5722' }}>{t('otrosServicios.buttonText')}</button>
              </div>

              <div style={styles.serviceCard}>
                <img src={capacitacionIcon} alt={t('otrosServicios.capacitacionTitle')} style={styles.icon} />
                <h3 style={styles.serviceTitle}>{t('otrosServicios.capacitacionTitle')}</h3>
                <p style={styles.serviceDescription}>{t('otrosServicios.capacitacionDescription')}</p>
                <button
                  onClick={handleServicioCapacitacionClick} 
                  style={{ ...styles.button, backgroundColor: '#FFC107' }}>{t('otrosServicios.buttonText')}</button>
              </div>
              
            </div>
          </div>
        </div>
        <div className='col-sm-3'>
        </div>
    </section>
  );
};

const styles = {
  servicesSection: {
    marginTop: '100px',
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    marginBottom: '180px',
    padding: '0 20px', // Evita el scroll horizontal en pantallas pequeñas
  },
  servicesGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', // Ajuste flexible para columnas
    gap: '20px',
    maxWidth: '900px',
    width: '100%',
  },
  serviceCard: {
    border: '1px solid #f0f0f0',
    borderRadius: '10px',
    textAlign: 'center', // Centrado de contenido en pantallas pequeñas
    backgroundColor: '#ffffff',
    boxShadow: '0 4px 28px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '20px',
    margin: '0 auto',
    maxWidth: '350px', // Controla el ancho máximo de la tarjeta
    '@media (max-width: 768px)': {
      maxWidth: '100%',
    },
  },
  icon: {
    width: '50px',
    height: '50px',
    marginBottom: '20px',
    display: 'block',
    margin: '0 auto', // Centra el ícono horizontalmente
  },
  serviceTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#004d40',
    marginBottom: '15px',
    fontFamily: "'Satoshi', sans-serif",
    textAlign: 'center', // Centra el título en pantallas pequeñas
  },
  serviceDescription: {
    fontSize: '16px',
    color: '#0A4231',
    marginBottom: '20px',
    fontFamily: "'Satoshi', sans-serif",
    textAlign: 'center', // Centrado de descripción en pantallas pequeñas
  },
  button: {
    padding: '10px 20px',
    color: '#ffffff',
    border: 'none',
    borderRadius: '50px',
    fontSize: '14px',
    fontFamily: 'Satoshi',
    fontWeight: 'bold',
    height: '37px',
    width: '146px',
    cursor: 'pointer',
    textTransform: 'uppercase',
    display: 'inline-block',
    marginTop: 'auto',
    alignSelf: 'center', // Centra el botón horizontalmente
  },
};

export default OtrosServicios;
