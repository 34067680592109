// src/components/sections/MarketingSection.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import NuestroServicioMkt from '../../organisms/NuestroServicioMkt';
import { styles } from './MarketingSection.styles';

const MarketingSection = ({ isMobile }) => {
    
  const { t } = useTranslation();

  return (
    <div style={styles.container(isMobile)} id="NuestroServicioMKT">
      <h3 style={styles.headerText}>{t('header.service')}</h3>
      <h1 style={styles.headerTitle}>{t('serviceTitles.socialMarketing')}</h1>
      <div style={styles.overlay}>
        <NuestroServicioMkt />
      </div>
    </div>
  );
};

export default MarketingSection;
