import React, { useState, useEffect } from 'react';
import './TitleComponent.css';

const TitleComponent = ({ text, color }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const style = {
    color: color || '#000', // Color por defecto negro
    textAlign: 'center', // Alineación centrada
    marginTop: isMobile ? '100px' : '200px', // Ajuste del margen superior según el tamaño de pantalla
    marginBottom: '50px', // Margen inferior
    fontSize: '45px', // Tamaño de fuente
    fontFamily: 'Anton',
  };

  return <h1 style={style}>{text}</h1>;
};

export default TitleComponent;
