import React from 'react';
import instagramIcon from '../../assets/images/instagram.png';
import linkedinIcon from '../../assets/images/linkedin.png';

const SocialIconsGroup = () => {

  
  const iconStyleDefault = {
    width: '34px',
    height: '34px',
    margin: '0 10px',
  };

  const linkedinStyle = {
    width: '34px',
    height: '34px',
    margin: '0',
  };

  return (
    <div>
      <a href="https://www.instagram.com/endemica_social/?igsh=ZjQyb2xzaGgwajBq" target="_blank" rel="noopener noreferrer">
        <img src={instagramIcon} alt="Instagram" style={iconStyleDefault} />
      </a>
      <a href="https://www.linkedin.com/company/end%C3%A9mica-social/" target="_blank" rel="noopener noreferrer">
        <img src={linkedinIcon} alt="LinkedIn" style={linkedinStyle} />
      </a>
    </div>
  );
};

export default SocialIconsGroup;
