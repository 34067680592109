import React from 'react';
import { useTranslation } from 'react-i18next';
import achievementsImage from '../../assets/images/34_Inicio_Assets/2_Mask_group.png';

const AchievementsSection = () => {
  const { t } = useTranslation();

  return (
    <section style={styles.achievementsSection} className="container-fluid">
      <div style={styles.content}>
        <div style={styles.textContent}>
          <div style={styles.achievement}>
            <h2 style={styles.highlight}>{t('achievementsSection.highlight1')}</h2>
            <p style={styles.description}>{t('achievementsSection.description1')}</p>
          </div>
          <div style={styles.achievement}>
            <h2 style={styles.highlight}>{t('achievementsSection.highlight2')}</h2>
            <p style={styles.description}>{t('achievementsSection.description2')}</p>
          </div>
          <div style={styles.achievement}>
            <h2 style={styles.highlight}>{t('achievementsSection.highlight3')}</h2>
            <p style={styles.description}>{t('achievementsSection.description3')}</p>
          </div>
          <div style={styles.achievement}>
            <h2 style={styles.highlight}>{t('achievementsSection.highlight4')}</h2>
            <p style={styles.description}>{t('achievementsSection.description4')}</p>
          </div>
        </div>
        <div style={styles.imageContent}>
          <img src={achievementsImage} alt="Achievements" style={styles.image} />
        </div>
      </div>
    </section>
  );
};

const styles = {
  achievementsSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '80px 20px',
    backgroundColor: '#ffffff',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '1200px',
    alignItems: 'center',
    flexWrap: 'wrap', // Permite el ajuste automático en pantallas pequeñas
  },
  textContent: {
    flex: 1,
    minWidth: '300px',
    marginBottom: '20px',
    padding: '0 20px', // Espaciado horizontal para mejorar legibilidad
    textAlign: 'left', // Centra el texto en pantallas pequeñas
  },
  achievement: {
    marginBottom: '20px',
  },
  highlight: {
    color: '#FF0080',
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '10px',
    fontFamily: 'Satoshi',
  },
  description: {
    color: '#004d40',
    fontSize: '18px',
    fontFamily: 'Satoshi',
  },
  imageContent: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    minWidth: '300px',
    padding: '0 20px', // Espaciado horizontal para mantener consistencia
  },
  image: {
    maxWidth: '100%', // Ajusta la imagen al 100% del contenedor
    height: 'auto',
  },

  // Media Queries para hacerlo responsivo
  '@media (max-width: 768px)': {
    content: {
      flexDirection: 'column',
    },
    textContent: {
      marginBottom: '20px',
      textAlign: 'center', // Asegura el centrado en dispositivos medianos
    },
    highlight: {
      fontSize: '28px', // Ajuste del tamaño para pantallas medianas
    },
    description: {
      fontSize: '16px', // Ajuste del tamaño para pantallas medianas
    },
  },

  '@media (max-width: 480px)': {
    achievementsSection: {
      padding: '40px 10px',
    },
    textContent: {
      padding: '0 10px', // Ajusta el espaciado horizontal en pantallas pequeñas
    },
    highlight: {
      fontSize: '24px', // Tamaño de fuente reducido en pantallas pequeñas
    },
    description: {
      fontSize: '14px', // Tamaño de fuente reducido en pantallas pequeñas
    },
    imageContent: {
      padding: '0 10px', // Ajusta el espaciado horizontal de la imagen en pantallas pequeñas
    },
  },
};


export default AchievementsSection;
