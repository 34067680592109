import React from 'react';
import { useTranslation } from 'react-i18next';
import gifFileES from '../../assets/images/34_Video_de_inicio_2.gif';
import gifFileEN from '../../assets/videos/VIDEO ENDEMICA INGLES GIF.gif'; // Asegúrate de que la ruta sea correcta.

const MainSection = () => {
  const { i18n } = useTranslation();

  // Selecciona el GIF basado en el idioma
  const gifFile = i18n.language === 'es' ? gifFileES : gifFileEN;

  return (
    <section style={mainSectionStyle}>
      <img src={gifFile} alt="Main GIF" style={mainGifStyle()} />
    </section>
  );
};

// Estilos en línea
const mainSectionStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '10px',
  padding: '0px',
};

const mainGifStyle = () => {
  return {
    width: '100%',
    marginTop: '80px',
    height: window.innerWidth <= 768 ? '230px' : '700px',
  };
};

export default MainSection;
