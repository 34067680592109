import React from 'react';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import 'bootstrap/dist/css/bootstrap.min.css';

// Definición del componente Button
const Button = ({ text, color, fontSize = '16px', onClick, widthButton = '300px', heightButton}) => {
  return (
    <button 
      style={{ 
        backgroundColor: color, 
        fontSize: fontSize, 
        fontFamily: 'Satoshi',
        padding: '10px 20px', 
        border: 'none', 
        height: heightButton,
        width: widthButton,
        borderRadius: '25px', 
        fontWeight: 'bold',
        color: '#fff', 
        cursor: 'pointer',
        marginTop: '30px'
      }} 
      onClick={onClick} // Asegúrate de pasar el onClick correctamente
    >
      {text}
    </button>
  );
};

// Definición del componente CallToAction
const CallToAction = ({ text, buttonText, buttonColor, widthButton, marginTop }) => {

  const navigate = useNavigate(); // Usa useNavigate para redireccionar

  const handleClick = () => {
    navigate('/contacto'); // Redirige a la página de contacto
    window.scrollTo(0, 0); // Desplaza al inicio de la pantalla
  };

  return (
    <div className='cotainer' style={{marginTop:marginTop, textAlign:'center'}}>
      <div className='row'>
        <div className='col-sm-2'>
        </div>
        <div className='col-sm-8'>
          <p style={{ color: '#00B07A', fontSize: '35px', margin: 0, fontFamily:'Anton' }}>
            {text}
          </p>
        </div>
        <div className='col-sm-2'>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-4'>
        </div>
        <div className='col-sm-4' style={{marginTop:'30px', marginBottom:'80px'}}>
          <Button 
              text={buttonText} 
              color={buttonColor}
              widthButton={'176px'}
              heightButton={'47px'}
              fontSize="16px" 
              onClick={handleClick} // Asocia la función handleClick al botón
            />
        </div>
        <div className='col-sm-4'>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
